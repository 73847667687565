.page-articel {
  padding-top: 48px;
}
.page-articel .image-box {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.page-articel .add-article-book .tag-search-box {
  width: 300px;
  margin-left: auto;
}
.page-articel .add-article-book .add {
  text-align: right;
  color: #5252fd;
}
.page-articel .add-article-book .book-item {
  margin-top: 20px;
}
.page-articel .add-article-book .book-item .label {
  text-align: right;
  padding-right: 10px;
}
.page-articel .add-article-book .book-item .del {
  text-align: right;
  color: red;
}
.page-articel .add-article-book .select-box {
  padding-bottom: 20px;
}
.page-articel .add-article-book .tag-search-box {
  margin-left: auto;
}
.page-articel .show-upload-list {
  display: flex;
}
.page-articel .show-upload-list .upload-item {
  width: 80px;
  font-size: 14px;
  color: #333;
  margin-left: 10px;
}
.page-articel .show-upload-list .upload-item .img {
  width: 80px;
  height: 80px;
  background-color: red;
  margin: 0 auto;
}
.page-articel .show-upload-list .upload-item .hs {
  line-height: 1;
  margin-top: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-articel .show-upload-list .upload-item .img-name {
  line-height: 1;
  margin-top: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-articel .show-upload-list .add {
  margin-left: 10px;
  width: 80px;
  height: 80px;
  font-size: 60px;
  border: 1px solid #333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-articel .active-box {
  height: 100px;
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
}
.page-articel .active-box button {
  margin-right: 16px;
}
.page-articel .active-box button:last-child {
  margin-right: 0;
}
.ant-modal-root .article-upload-list {
  display: flex;
  flex-wrap: wrap;
}
.ant-modal-root .article-upload-list .upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 170px;
  padding: 10px 10px 15px;
  background-color: #eee;
  border-radius: 8px;
}
.ant-modal-root .article-upload-list .upload-item .img {
  height: 100px;
  background-color: red;
}
.ant-modal-root .article-upload-list .upload-item .hs {
  margin-top: 8px;
}
.ant-modal-root .article-upload-list .upload-item .img-name {
  margin-top: 8px;
}
.ant-modal-root .article-upload-list .add {
  margin-top: 10px;
  margin-left: 10px;
  width: 170px;
  height: 205px;
  font-size: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}
