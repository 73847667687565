.wrapper-main-body {
  padding-right: 16px;
  box-sizing: border-box;
}
.wrapper-main-body .page-title {
  height: 43px;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #eee;
  margin-bottom: 24px;
}
.wrapper-main-body .page-active-box {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;
}
