.main_body .company-icon {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  background-color: #00284d;
}
.main_body .company-icon .logo {
  width: 32px;
  height: 32px;
  background: url(images/u31.png) no-repeat center center / cover;
}
.main_body .company-icon .logo-name {
  font-style: italic;
  color: #d7d7d7;
  font-size: 24px;
  margin-left: 26px;
  letter-spacing: 20px;
}
.main_body .user-info {
  display: flex;
  font-size: 14px;
  padding-right: 50px;
}
.main_body .user-info .edit-password {
  margin: 0 20px;
  color: #1890ff;
}
.main_body .user-info .go-out {
  color: #1890ff;
}
