.search-content {
    background: #fff;
    padding: 20px;
    border: 1px solid #eee;
}
.search-form .ant-form-item-control-wrapper {
    flex: 1;
}

.search-form .ant-form-item {
    margin-bottom: 5px;
    display: flex;
    padding: 0 10px;
}

.search-form button {
    font-size: 14px;
}

.table-select-info{
    margin-bottom: 10px !important;
}